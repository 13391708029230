import { PAYMENT_TYPE_NAMES } from './PaymentHelper';
import {
  ENUM_SUPPORTER_TYPE,
  getSupportProductName,
  MEMBER_TYPE_FLATMAP,
  MEMBER_TYPE_INDIVIDUAL,
  SUPPORT_TERM_ONCE,
  TYPE_MEMBER_TYPES,
} from './SupportHelper';
import dayjs from 'dayjs';
import SessionStorage from '@/services/SessionStorage';

declare global {
  interface Window {
    dataLayer?: any;
  }
}

interface ViewIgItemData {
  itemId?: string;
  itemName?: string;
  itemCategory?: string;
  itemListName?: string;
  itemBreadscrumb?: string;
  itemBrand?: string;
}

interface AddToCartData extends ViewIgItemData {
  value: string;
  price: string;
  quantity: string;
}

export interface SelectItemData extends ViewIgItemData {
  campaignName: string;
  campaignPagenum: string;
  promotionName: string;
  promotionId: string;
}

export const SelectItemDefaultData = {
      campaignName: 'none',
      campaignPagenum: '',
      promotionName: 'none',
      promotionId: '',
      itemListName: '',
      itemBreadscrumb: '',
      itemBrand: '',
}

export const SelectItemDefaultIGData = {
      campaignName: 'InspiredGift',
      campaignPagenum: 'D302',
      promotionName: 'InspiredGift',
      promotionId: 'D302',
      itemListName: '',
      itemBreadscrumb: '',
      itemBrand: '',
}

export interface BeginCheckoutData {
  campaignName?: string;
  campaignPagenum?: string;
  promotionName?: string;
  promotionId?: string;
  value?: string;
  items?: {
    itemId?: string;
    itemName?: string;
    itemCategory?: string;
    itemCategory2?: string;
    itemCategory3?: string;
    itemListName?: string;
    quantity?: string;
    price?: string;
  }[];
}

type AddDonorInfoData = BeginCheckoutData;

interface AddPaymentInfoData extends AddDonorInfoData {
  // 결제수단
  paymentType?: keyof typeof PAYMENT_TYPE_NAMES;
}

export interface PurchaseData extends AddPaymentInfoData {
  // 후원 고유 ID
  transactionId?: string;
}

interface EditPayMoneyData {
  // 후원 분야
  supportCategory: GatsbyTypes.SupportCategory;
  // 변경 전 금액
  oldAmount: string;
  // 변경 후 금액
  newAmount: string;
  // 데이터 수집되는 영역 (왼쪽)
  text1: string;
  // 데이터 수집되는 영역 (오른쪽)
  text2: string;
  // 이미지 파일명
  imgNm: string;
}

interface EditPayMethodData {
  // 변경 전 금액
  oldPayDay: string;
  // 변경 후 금액
  newPayDay: string;
  // 변경 전 결제수단
  oldPayTypeCd: string;
  // 변경 후 결제수단
  newPayTypeCd: string;
}

const SOCIAL_NAMES = {
  N: `네이버`,
  F: `페이스북`,
  G: `구글`,
};
interface LoginData {
  socialType?: 'N' | 'F' | 'G' | null;
  userId: string;
  donorId?: string | null;
  birth?: string | null; // YYMMDD
  fullBirth?: string | null; //YYYYMMDD
  gender?: string | null; // M/F
  // 개인정보 이슈로 전송 중단
  // fullJuminno?: string | null;
}

interface JoinData {
  socialType?: 'N' | 'F' | 'G';
  isSuccess: boolean;
  userId: string;
}

const setItemCategory4 = (gtm_item_cateogyr4 : string) => {
  SessionStorage.setItem('gtm_item_cateogyr4', gtm_item_cateogyr4 || '');
}
const getItemCategory4 = () => {
  return SessionStorage.getItem('gtm_item_cateogyr4') || null;
}

const GtmHelper = {
  // 생명을 구하는 선물 장바구니 추가
  addToCart(data: AddToCartData): void {
    // GTM이 로드되지 않았다면 실행x
    if (!window.dataLayer) {
      console.debug(`gtm addToCart`);
      return;
    }
    // ecommerce datalayer 삭제
    window.dataLayer.push(function () {
      const layer = this.get('ecommerce');
      if (layer) {
        this.set('ecommerce', undefined);
      }
    });
    const {
      value,
      itemId,
      itemName,
      itemCategory,
      itemListName,
      price,
      quantity,
    } = data;
    window.dataLayer.push({
      event: `add_to_cart`, // 값 고정
      event_category: `Enhanced Ecommerce`, // 값 고정
      campaignName: `InspiredGift`, // 값 고정
      campaignPagenum: `D302`, // 값 고정
      promotion_name: `InspiredGift`, // 값 고정
      promotion_id: `D302`, // 값 고정
      ecommerce: {
        currencyCode: `KRW`, // 값 고정
        value, // 총 금액
        items: [
          {
            item_id: itemId, // 후원상품ID (제품번호)
            item_name: itemName, // 후원상품명
            item_category: itemCategory, // 후원상품유형
            item_category4: getItemCategory4(), //breadcrumb
            item_list_name: itemListName, // IG상품구분
            price, // 단가
            quantity, // 수량
          },
        ],
      },
    });
  },
  // 생명을 구하는 선물 상세 페이지 조회
  viewIgItem(data: ViewIgItemData): void {
    // GTM이 로드되지 않았다면 실행x
    if (!window.dataLayer) {
      console.debug(`gtm viewIgItem`);
      return;
    }
    // ecommerce datalayer 삭제
    window.dataLayer.push(function () {
      const layer = this.get('ecommerce');
      if (layer) {
        this.set('ecommerce', undefined);
      }
    });
    const { itemId, itemName, itemCategory, itemListName } = data;
    window.dataLayer.push({
      event: `view_item`, // 값 고정
      event_category: `Enhanced Ecommerce`, // 값 고정
      campaignName: `InspiredGift`, // 값 고정
      campaignPagenum: `D302`, // 값 고정
      promotion_name: `InspiredGift`, // 값 고정
      promotion_id: `D302`, // 값 고정
      ecommerce: {
        items: [
          {
            item_id: itemId, // 후원상품ID (제품번호)
            item_name: itemName, // 후원상품명
            item_category: itemCategory, // 후원상품유형
            item_category4: getItemCategory4(), //breadcrumb
            item_list_name: itemListName, // IG상품구분
          },
        ],
      },
    });
  },
  // 후원하기 버튼 클릭시
  selectItem(data: SelectItemData): void {
    // GTM이 로드되지 않았다면 실행x
    if (!window.dataLayer) {
      console.debug(`gtm selectDonate`);
      return;
    }
    // ecommerce datalayer 삭제
    window.dataLayer.push(function () {
      const layer = this.get('ecommerce');
      if (layer) {
        this.set('ecommerce', undefined);
      }
    });
    const {
      campaignName,
      campaignPagenum,
      itemCategory,
      itemId,
      itemListName,
      itemName,
      itemBreadscrumb,
      itemBrand,
      promotionId,
      promotionName,
    } = data;

    setItemCategory4(itemBreadscrumb || '');

    window.dataLayer.push({
      event: `select_item`, // 값 고정
      event_category: `Enhanced Ecommerce`, // 값 고정
      campaignName, // 캠페인/이벤트 명
      campaignPagenum, // 캠페인/이벤트 ID
      promotion_name: promotionName, // 캠페인/이벤트 명
      promotion_id: promotionId, // 캠페인/이벤트 ID
      ecommerce: {
        items: [
          {
            item_id: itemId, // 후원상품ID (제품번호)
            item_name: itemName, // 후원상품명
            item_category: itemCategory, // 후원상품유형
            item_category2:'', //생년월일 또는 사업자번호
            item_category3: '', //주민번호 7번째 자리
            item_category4: getItemCategory4(), //breadcrumb
            item_brand: itemBrand, //IG후원상품구분
            item_list_name: itemListName, // IG패키지구분
          },
        ],
      },
    });
  },
  // 후원하기 (step1)
  beginCheckout(data: BeginCheckoutData): void {
    // GTM이 로드되지 않았다면 실행x
    if (!window.dataLayer) {
      console.debug(`gtm checkout step1`, data);
      return;
    }
    // ecommerce datalayer 삭제
    window.dataLayer.push(function () {
      const layer = this.get('ecommerce');
      if (layer) {
        this.set('ecommerce', undefined);
      }
    });
    const {
      campaignName,
      campaignPagenum,
      promotionName,
      promotionId,
      value,
      items,
    } = data;
    window.dataLayer.push({
      event: `begin_checkout`, // 값 고정
      event_category: 'Enhanced Ecommerce', // 값 고정
      campaignName, // 캠페인/이벤트 명
      campaignPagenum, // 캠페인/이벤트 ID
      promotion_name: promotionName, // 캠페인/이벤트 명
      promotion_id: promotionId, // 캠페인/이벤트 ID
      ecommerce: {
        currency: `KRW`, // 값 고정
        value, // 총 금액
        items: items?.map((item) => ({
          item_id: item.itemId, // 후원상품ID (제품번호)
          item_name: item.itemName, // 후원상품명
          item_category: item.itemCategory, // 후원상품유형
          item_category2: item.itemCategory2, // 생년
          item_category3: item.itemCategory3, // 성별
          item_category4: getItemCategory4(), //breadcrumb
          item_list_name: item.itemListName, // IG상품구분
          price: item.price, // 단가
          quantity: item.quantity, // 수량
        })),
      },
    });
  },
  // 후원하기 (step2)
  addDonorInfo(data: AddDonorInfoData): void {
    // GTM이 로드되지 않았다면 실행x
    if (!window.dataLayer) {
      console.debug(`gtm checkout step2`, data);
      return;
    }
    // ecommerce datalayer 삭제
    window.dataLayer.push(function () {
      const layer = this.get('ecommerce');
      if (layer) {
        this.set('ecommerce', undefined);
      }
    });
    const {
      campaignName,
      campaignPagenum,
      promotionName,
      promotionId,
      value,
      items,
    } = data;
    window.dataLayer.push({
      event: `add_donor_info`, // 값 고정
      event_category: 'Enhanced Ecommerce', // 값 고정
      campaignName, // 캠페인/이벤트 명
      campaignPagenum, // 캠페인/이벤트 ID
      promotion_name: promotionName, // 캠페인/이벤트 명
      promotion_id: promotionId, // 캠페인/이벤트 ID
      ecommerce: {
        currency: `KRW`, // 값 고정
        value, // 총 금액
        items: items?.map((item) => ({
          item_id: item.itemId, // 후원상품ID (제품번호)
          item_name: item.itemName, // 후원상품명
          item_category: item.itemCategory, // 후원상품유형
          item_category2: item.itemCategory2, // 생년
          item_category3: item.itemCategory3, // 성별
          item_category4: getItemCategory4(), //breadcrumb
          item_list_name: item.itemListName, // IG상품구분
          price: item.price, // 단가
          quantity: item.quantity, // 수량
        })),
      },
    });
  },
  // 후원하기 (step3)
  addPaymentInfo(data: AddPaymentInfoData): void {
    // GTM이 로드되지 않았다면 실행x
    if (!window.dataLayer) {
      console.debug(`gtm checkout step3`, data);
      return;
    }
    // ecommerce datalayer 삭제
    window.dataLayer.push(function () {
      const layer = this.get('ecommerce');
      if (layer) {
        this.set('ecommerce', undefined);
      }
    });
    const {
      campaignName,
      campaignPagenum,
      promotionName,
      promotionId,
      value,
      items,
      paymentType,
    } = data;
    window.dataLayer.push({
      event: `add_payment_info`, // 값 고정
      event_category: 'Enhanced Ecommerce', // 값 고정
      campaignName, // 캠페인/이벤트 명
      campaignPagenum, // 캠페인/이벤트 ID
      promotion_name: promotionName, // 캠페인/이벤트 명
      promotion_id: promotionId, // 캠페인/이벤트 ID
      ecommerce: {
        currency: `KRW`, // 값 고정
        value, // 총 금액
        payment_type: paymentType && PAYMENT_TYPE_NAMES[paymentType], // 결제 수단
        items: items?.map((item) => ({
          item_id: item.itemId, // 후원상품ID (제품번호)
          item_name: item.itemName, // 후원상품명
          item_category: item.itemCategory, // 후원상품유형
          item_category2: item.itemCategory2, // 생년
          item_category3: item.itemCategory3, // 성별
          item_category4: getItemCategory4(), //breadcrumb
          item_list_name: item.itemListName, // IG상품구분
          price: item.price, // 단가
          quantity: item.quantity, // 수량
        })),
      },
    });
  },
  // 후원완료
  purchase(data: PurchaseData): void {
    // GTM이 로드되지 않았다면 실행x
    if (!window.dataLayer) {
      console.debug(`gtm purchase`, data);
      return;
    }
    // ecommerce datalayer 삭제
    window.dataLayer.push(function () {
      const layer = this.get('ecommerce');
      if (layer) {
        this.set('ecommerce', undefined);
      }
    });
    const {
      campaignName,
      campaignPagenum,
      promotionName,
      promotionId,
      value,
      items,
      paymentType,
      transactionId,
    } = data;
    window.dataLayer.push({
      event: `purchase`, // 값 고정
      event_category: 'Enhanced Ecommerce', // 값 고정
      campaignName, // 캠페인/이벤트 명
      campaignPagenum, // 캠페인/이벤트 ID
      promotion_name: promotionName, // 캠페인/이벤트 명
      promotion_id: promotionId, // 캠페인/이벤트 ID
      ecommerce: {
        currency: `KRW`, // 값 고정
        value, // 총 금액
        payment_type: paymentType && PAYMENT_TYPE_NAMES[paymentType], // 결제 수단
        transaction_id: transactionId,
        donation_type: items && items[0].itemCategory,
        items: items?.map((item) => ({
          item_id: item.itemId, // 후원상품ID (제품번호)
          item_name: item.itemName, // 후원상품명
          item_category: item.itemCategory, // 후원상품유형
          item_category2: item.itemCategory2, // 생년
          item_category3: item.itemCategory3, // 성별
          item_category4: getItemCategory4(), //breadcrumb
          item_list_name: item.itemListName, // IG상품구분
          price: item.price, // 단가
          quantity: item.quantity, // 수량
        })),
      },
    });

    // 후원 완료 후 item_category4 초기화
    window.dataLayer.push(function () {
      const layer = this.get('ecommerce');
      if (layer) {
        if (layer?.items[0]?.item_category4) {
          layer.items[0].item_category4 = undefined;
        }
        this.set('item_category4', layer);
      }
    });
  },
  // 후원금액 추가
  addPayMoney(data: EditPayMoneyData): void {
    const { supportCategory, oldAmount, newAmount, text1, text2, imgNm } = data;
    // GTM이 로드되지 않았다면 실행x
    if (!window.dataLayer) {
      console.debug(`gtm addPayMoney`, data);
      return;
    }

    window.dataLayer.push({
      event: `add_paymoney`,
      et_category: `Retention - Add Paymoney`, // 고정값
      et_action: getSupportProductName(supportCategory), // {{Donation Type}} - {{Donation Name}}
      et_label: `${oldAmount} - ${newAmount}`, // {{기존 후원금액}} - {{현재 후원금액}}
      data_collect_slot: text1,
      data_collect_tab: text2,
      img_src: imgNm,
    });
  },
  // 후원금액 변경
  editPayMoney(data: EditPayMoneyData): void {
    const { supportCategory, oldAmount, newAmount } = data;
    // GTM이 로드되지 않았다면 실행x
    if (!window.dataLayer) {
      console.debug(`gtm editPayMoney`, data);
      return;
    }

    window.dataLayer.push({
      event: `edit_paymoney`,
      et_category: `Retention - Edit Paymoney`, // 고정값
      et_action: getSupportProductName(supportCategory), // {{Donation Type}} - {{Donation Name}}
      et_label: `${oldAmount} - ${newAmount}`, // {{기존 후원금액}} - {{현재 후원금액}}
    });
  },
  // 결제정보 변경
  editPayMethod(data: EditPayMethodData): void {
    const { oldPayDay, newPayDay, oldPayTypeCd, newPayTypeCd } = data;
    // GTM이 로드되지 않았다면 실행x
    if (!window.dataLayer) {
      console.debug(`gtm editPayMethod`, data);
      return;
    }

    window.dataLayer.push({
      event: `edit_paymoney`,
      et_category: `Retention - Edit Paymethod`, // 고정값
      et_action: `${PAYMENT_TYPE_NAMES[oldPayTypeCd]} - ${PAYMENT_TYPE_NAMES[newPayTypeCd]}`, // {{변경 전 결제(수단) 정보}} - {{변경 후 결제(수단) 정보}}
      et_label: `${oldPayDay} - ${newPayDay}`, // {{기존 후원금액}} - {{현재 후원금액}}
    });
  },
  // 로그인
  login(data: LoginData): void {
    // GTM이 로드되지 않았다면 실행x
    if (!window.dataLayer) {
      console.debug(`gtm login`, data);
      return;
    }
    const { socialType, userId, donorId, birth, fullBirth, gender } = data;

    window.dataLayer.push({
      event: `login`,
      method: socialType || `일반`, // {{일반 or N or F or G}}
      userId, // user ID (유저 식별 정보)
      donorId: !donorId || donorId === '0' ? '' : donorId, // 후원자 ID
      gender: gender, // 사용자 성별
      birth:
         fullBirth && dayjs(fullBirth, `YYYYMMDD`, true).isValid() 
          ? `${fullBirth.slice(0, 4)}`
          : '', // 사용자 생년
    });
  },
  // 회원가입
  join(data: JoinData): void {
    // GTM이 로드되지 않았다면 실행x
    if (!window.dataLayer) {
      console.debug(`gtm join`, data);
      return;
    }
    const { socialType, isSuccess, userId } = data;
    window.dataLayer.push({
      event: `registration_event`,
      et_category: `회원가입`, // 고정 값
      et_action: socialType ? SOCIAL_NAMES[socialType] : `유니세프`, // {{유니세프 or 네이버 or 페이스북 or 구글}}
      et_label: isSuccess ? `성공` : `실패`, // {{성공 or 실패}}
      userId, // user ID (유저 식별 정보)
    });
  },
};

export default GtmHelper;
