import { usePopupStore } from './PopupStore';
import {
  MemberInfoControllerService,
  MemberLoginControllerService,
  SocialLoginControllerService,
  serviceOptions as commonServiceOptions,
} from '@/__generated__/CommonApi';
import { serviceOptions as frontServiceOptions } from '@/__generated__/FrontApi';
import JwtExpiredError from '@/errors/JwtExpiredError';
import { isBrowser } from '@/helpers/BrowserHelper';
import GtmHelper from '@/helpers/GtmHelper';
import {
  MEMBER_TYPE_CORPORATION,
  MEMBER_TYPE_INDIVIDUAL,
  MEMBER_TYPE_INDIVIDUAL_BUSINESS,
} from '@/helpers/SupportHelper';
import LocalStorage from '@/services/LocalStorage';
import SessionStorage, { SESSION_STORAGE_KEY__MYPAGE_AUTH } from '@/services/SessionStorage';
import jwtDecode, { InvalidTokenError } from 'jwt-decode';
import { observable, runInAction } from 'mobx';

export interface User {
  userId: string;
  name: string;
  email: string;
  mobilePhoneNumber: string;
  socialId: string | null;
  socialType: 'G' | 'F' | 'N' | null;
  // 마스킹된 주민번호
  juminno: string;
  juminnoLength: number;
  // 대시 없는 주민번호. 개인정보 이슈로 전송 중단
  // fullJuminno: string;
  businessNumber: string;
  // 생일 (웹DB)
  birthDay: string | null; // YYMMDD
  fullBirthDay: string | null; // YYYYMMDD
  gender: string | null; // M / F

  // 후원정보
  donorId: string;
  donorType: 'person' | string;
  donorTypeCode: 'IN' | string;
  donorYn: 'Y' | 'N';
  firstDonateDate: string | null; // 첫 후원일
  accumTotalAmount: number; // 누적 총 후원금액
  donorHistoryCount: number; // 실제 결제한 히스토리 카운트 (후원한적 있는지 여부 판단용)
  ptypeCount: number; // 정기후원 횟수
  gtypeCount: number; // 일시후원 횟수
  regularTotalAmount: number; // 현재 정기후원 총 금액

  // 광고관련
  emailAgreeYn: 'Y' | 'N';
  smsAgreeYn: 'Y' | 'N';
  mailEdm: 'Y' | 'N';
  mailSms: 'Y' | 'N';
  mailDm: 'Y' | 'N';

  // 주소
  zip: string;
  address1: string;
  address2: string;

  // 기타 설정
  isRewardYn: 'Y' | 'N';
}

interface TokenPayload {
  exp: number;
}

const decodeToken = (token: string) => jwtDecode<TokenPayload>(token);

interface UserStoreProps {
  user: User | null;
  token: string | null;
  refreshToken: string | null;
  // 데이터가 충분히 로딩되었는지 여부 (첫 로딩시 사용)
  loaded: boolean;
  // 토큰 갱신
  refreshJwt: () => Promise<boolean>;
  loadUserInfo: (
    isLogin?: boolean,
    accessToken?: string,
  ) => Promise<User | false>;
  isLoggedIn: () => boolean;
  login: (params: {
    loginId: string;
    loginPw: string;
  }) => Promise<string | null>;
  loginSocial: (
    social: 'naver' | 'facebook' | 'google',
    accessToken: string,
  ) => Promise<
    | false
    | {
        returnMessage: string;
        email: string;
        socialId?: string;
        socialType?: 'N' | 'F' | 'G';
      }
  >;
  logout: () => void;
  // 소셜회원 여부
  isSocial: () => boolean;
  // 후원연결 여부
  isDonor: () => boolean;
  // 정기후원자 여부
  isRegularDonor: () => boolean;
  // 광고관련 동의데이터
  isSmsAgree: () => boolean;
  isEmailAgree: () => boolean;
  isPostAgree: () => boolean;
  // 주민번호, 사업자번호 올바른지 여부
  isValidJuminno: () => boolean;
  // 개인, 기업인지 여부
  isIndividualUser: () => boolean;
  isBusinessUser: () => boolean;
  // 생년월일 가져오기
  getBirthday: () => string | null;
  // 생년월일 가져오기
  getFullBirthday: () => string | null;
}

// Store 생성
const UserStore = observable.object<UserStoreProps>({
  user: null,
  token: LocalStorage.getItem(`token`),
  refreshToken: LocalStorage.getItem(`refreshToken`),
  loaded: false,
  async refreshJwt() {
    // 토큰이 없으면 취소
    if (!this.token || !this.refreshToken) {
      runInAction(() => {
        this.loaded = true;
      });
      return false;
    }

    console.log(`토큰 갱신 요청`);

    try {
      // 토큰 파싱 (throw InvalidTokenError)
      const info = decodeToken(this.token);
      // 토큰 만료 확인
      if (info.exp * 1000 < new Date().getTime()) {
        throw new JwtExpiredError(`토큰이 만료되었습니다`);
      }

      const {
        resultCode,
        data: _data,
      } = await MemberLoginControllerService.refreshTokenUsingPost1({
        headers: {
          'X-AUTH-REFRESH-TOKEN': this.refreshToken,
          'X-AUTH-TOKEN': this.token,
        },
      });

      const data = _data as any;

      if (resultCode === `success`) {
        LocalStorage.setItem(`token`, data.accessToken);
        runInAction(() => {
          this.token = LocalStorage.getItem(`token`);
          // 사용자 정보 업데이트
          this.loadUserInfo();
        });
        return true;
      }
    } catch (e) {
      // 실패시 로그아웃 처리
      this.logout();
      // 첫 로딩이 아닌경우
      if (this.loaded) {
        console.error(e);
        // 에러메세지 처리
        const popupStore = usePopupStore();
        // 400 에러면
        if (e.response && e.response.status === 400) {
          popupStore.show(`로그인 후 이용 가능합니다.`);
        }
        // 토큰 파싱에러, 토큰 만료에러면
        else if (e instanceof JwtExpiredError) {
          popupStore.show(`세션이 만료되었습니다`);
        }
        // 파싱에러면
        else if (e instanceof InvalidTokenError) {
          // 조용히 실패
        }
      }
      // 로딩 완료 처리
      runInAction(() => {
        this.loaded = true;
      });
    }

    return false;
  },

  async loadUserInfo(isLogin, accessToken) {
    // 토큰이 없으면 취소
    if (!this.token && !accessToken) {
      runInAction(() => {
        this.loaded = true;
      });
      return false;
    }

    try {
      // 회원정보 로딩
      const {
        data: _data,
        resultCode,
      } = await MemberInfoControllerService.memberLoginInfoUsingGet({
        myInfoToken: SessionStorage.getItem(SESSION_STORAGE_KEY__MYPAGE_AUTH)
      },{
        headers: {
          'X-AUTH-TOKEN': accessToken ?? this.token,
        },
      });

      const data = _data as User;

      try {
        let cryptoKey = accessToken ?? this.token;
        if (cryptoKey) {
          const cryptoKeySize = 32;
          if (cryptoKey.length > cryptoKeySize) {
            cryptoKey = cryptoKey.substring(cryptoKey.length - cryptoKeySize);
          }
        }
      } catch (e) {
        //console.log(e);
      }

      if (resultCode === `success`) {
        runInAction(() => {
          this.user = data;
          // 로딩 완료
          if (this.user.name.includes('&amp;')) {
            this.user.name = this.user.name.replaceAll('amp;', '');
          }
          this.loaded = true;

          // 로그인 직후 가져오는 경우면, GTM 로그인 성공 이벤트 발생
          if (isLogin) {
            GtmHelper.login({
              userId: data.userId,
              socialType: data.socialType,
              donorId: data.donorId,
              birth: data.birthDay,
              fullBirth: data.fullBirthDay,
              gender: data.gender,
            });
          }
        });
        return data;
      }
      alert(`회원정보를 가져오는데 실패했습니다`);
    } catch (e) {
      console.error(e);
    }

    // 로딩 완료
    runInAction(() => {
      this.loaded = true;
    });

    return false;
  },

  // 로그인 여부
  isLoggedIn() {
    return !!this.user;
  },

  async login(params) {
    this.loaded = false;

    try {
      // 로그인 request
      const {
        data: originalData,
        resultCode,
      } = await MemberLoginControllerService.memberLoginUsingPost({
        id: params.loginId,
        password: params.loginPw,
      });

      // 타입에러 보정
      const data = originalData as any;

      if (resultCode === `success` && data) {
        LocalStorage.setItem(`token`, data.accessToken);
        LocalStorage.setItem(`refreshToken`, data.refreshToken);
        runInAction(() => {
          this.token = LocalStorage.getItem(`token`);
          this.refreshToken = LocalStorage.getItem(`refreshToken`);
          // 사용자 정보 업데이트
          this.loadUserInfo(true);
        });
        return null;
      }

      if (resultCode === `failed` && data.returnMessage) {
        runInAction(() => {
          this.loaded = true;
        });
        return data.returnMessage;
      }
    } catch (e: any) {
      runInAction(() => {
        this.loaded = true;
      });

      if (e.response) {
        if (e.response.data?.data?.returnMessage) {
          return e.response.data.data.returnMessage;
        }
        if (e.response.status === 500) {
          return `서버 오류가 발생했습니다.`;
        }
      }
    }

    return `로그인 실패`;
  },
  logout() {
    this.user = null;
    this.token = null;
    this.refreshToken = null;
    LocalStorage.removeItem(`token`);
    LocalStorage.removeItem(`refreshToken`);
    SessionStorage.clear();
  },
  /**
   * 소셜 로그인
   * (에러가 없으면 false, 에러가 있다면 에러를 리턴)
   */
  async loginSocial(social, accessToken) {
    this.loaded = false;
    try {
      const {
        resultCode,
        data: _data,
      } = await SocialLoginControllerService.naverLoginUsingGet({
        accessToken,
        social,
      });

      // 타입에러 보정
      const data = _data as any;
      if (resultCode === `success` && data) {
        LocalStorage.setItem(`token`, data.accessToken);
        LocalStorage.setItem(`refreshToken`, data.refreshToken);
        // console.log(`token - ${data.accessToken}`);
        runInAction(() => {
          // console.log(`token2 - ${LocalStorage.getItem(`token`)}`);
          // this.token = LocalStorage.getItem(`token`);
          // this.refreshToken = LocalStorage.getItem(`refreshToken`);
          this.token = data.accessToken;
          this.refreshToken = data.refreshToken;
          // 사용자 정보 업데이트
          this.loadUserInfo(true, data.accessToken);
        });
        return false;
      }

      if (resultCode === `failed`) {
        runInAction(() => {
          this.loaded = true;
        });
        return data;
      }
    } catch (e) {
      console.error(e.response);
    }

    return true;
  },

  async loginSocial_test(social, accessToken, email) {
    try {
      // if (email === `bbalriga@naver.com` || email === `wevecld_ads@naver.com`) {
      console.log(accessToken);
      // }
    } catch (e) {}
    this.loaded = false;
    try {
      const {
        resultCode,
        data: _data,
      } = await SocialLoginControllerService.naverLoginUsingGet({
        accessToken,
        social,
      });

      try {
        /* if (
          email === `bbalriga@naver.com` ||
          email === `wevecld_ads@naver.com`
        ) { */
        console.log(_data.accessToken);
        // }
      } catch (e) {}

      try {
        // 타입에러 보정
        const data = _data as any;

        // if (resultCode === `success` && data) {
        // if (resultCode === `success`) {
        try {
          LocalStorage.setItem(`token`, data.accessToken);
          LocalStorage.setItem(`refreshToken`, data.refreshToken);
        } catch (e) {
          alert(`1-${JSON.stringify(e)}`);
        }
        console.log(`token - ${data.accessToken}`);
        runInAction(() => {
          console.log(`token2 - ${LocalStorage.getItem(`token`)}`);
          // this.token = LocalStorage.getItem(`token`);
          // this.refreshToken = LocalStorage.getItem(`refreshToken`);
          this.token = data.accessToken;
          this.refreshToken = data.refreshToken;
          // 사용자 정보 업데이트
          this.loadUserInfo(true);
        });

        return false;
        // }
      } catch (e) {
        alert(`2-${JSON.stringify(e)}`);
      }
      if (resultCode === `failed`) {
        window.setTimeout(function () {
          runInAction(() => {
            this.loaded = true;
          });
        }, 500);
        return data;
      }
    } catch (e) {
      console.error(e.response);
    }

    return true;
  },
  isSocial() {
    return !!this.user?.socialId;
  },
  isDonor() {
    return this.user?.donorYn === `Y`;
  },
  isRegularDonor() {
    return !!this.user && this.user.ptypeCount > 0;
  },
  isSmsAgree() {
    return (
      (this.isDonor() ? this.user?.mailSms : this.user?.smsAgreeYn) === `Y`
    );
  },
  isEmailAgree() {
    return (
      (this.isDonor() ? this.user?.mailEdm : this.user?.emailAgreeYn) === `Y`
    );
  },
  isPostAgree() {
    return this.user?.mailDm === `Y`;
  },
  isValidJuminno() {
    if (!this.user) {
      return false;
    }
    /*
    // 개인의 경우 주민번호 체크
    if (this.isIndividualUser() && this.user.juminnoLength === 13) {
      return true;
    }
    */

    if (
      this.isIndividualUser() &&
      this.user.juminnoLength === 13 &&
      this.user.juminno.length >= 6
    ) {
      const yy = Number(this.user.juminno.substring(0, 2));
      const mm = Number(this.user.juminno.substring(2, 4));
      const dd = Number(this.user.juminno.substring(4, 6));

      // eslint-disable-next-line no-useless-concat
      let dateObj = new Date(`20${yy}/${mm}/${dd}`);

      // 사파리 브라우저는 yyyy-mm-dd 형식 지원하지 않음으로 처리
      if (isSafari()) {
        // dateObj = new Date(`${dd}/${mm}/20${yy}`);
        dateObj = new Date(`20${yy}`, `${mm - 1}`, `${dd}`);
      }

      if (dateObj == `Invalid Date`) {
        return false;
      }

      /*
      if (new Date(`20${yy}-${mm}-${dd}`) == `Invalid Date`) {
        return false;
      }
      */

      return true;
    }

    // 기업의 경우 사업자번호 체크
    if (this.isBusinessUser() && this.user.juminnoLength === 10) {
      return true;
    }

    return false;
  },
  isIndividualUser() {
    return !!this.user && this.user.donorTypeCode === MEMBER_TYPE_INDIVIDUAL;
  },
  isBusinessUser() {
    return (
      !!this.user &&
      [MEMBER_TYPE_CORPORATION, MEMBER_TYPE_INDIVIDUAL_BUSINESS].includes(
        this.user.donorTypeCode,
      )
    );
  },
  
  getBirthday() {
    const birthDay = this.user?.fullBirthDay && this.user.fullBirthDay.length >= 8 ? this.user.fullBirthDay?.substring(2, 8) : null;
    return this.isIndividualUser() && this.user
      ? this.user.birthDay || birthDay
      : null;
  },

  getFullBirthday() {
    return this.isIndividualUser() && this.user
      ? this.user.fullBirthDay
      : null;
  },
});
function isSafari() {
  const ua = window.navigator.userAgent.toLowerCase();
  return (
    ua.indexOf(`safari`) >= 0 &&
    ua.indexOf(`chrome`) < 0 &&
    ua.indexOf(`android`) < 0
  );
}

// 처음 로딩때 토큰 refresh 시도하며, 사용자 정보 초기 로딩
UserStore.refreshJwt();

// 주기적으로 토큰 refresh (브라우저 활성 세션 유지)
setInterval(() => {
  UserStore.refreshJwt();
}, 1000 * 60 * 30); // 30분 간격

// axios response 인터셉터
if (isBrowser) {
  [commonServiceOptions.axios, frontServiceOptions.axios].forEach(
    (instance) => {
      instance?.interceptors.response.use(
        (response) =>
          // Do something with response data
          response,
        (error) => {
          // 인증오류고, 화면쪽이면
          if (error.response?.status === 401) {
            const popupStore = usePopupStore();
            popupStore.show(`로그인 후 이용 가능합니다.`);
            UserStore.logout();
          }
          // Do something with response error
          return Promise.reject(error);
        },
      );
    },
  );
}

// 커스텀 훅 정의
export const useUserStore: () => typeof UserStore = () => UserStore;

export default UserStore;
